<template>
    <div>
       <SearchbarHdr />
         <router-view :key="$route.path"></router-view>       <Footer />
    </div>
</template>
<script>
import SearchbarHdr from './TheHeader.vue'
import Footer from './WebAppFooter.vue'
export default {
    name:"WebAppContainer",
    components:{
        SearchbarHdr,
        Footer
    }
}
</script>
<style scoped>

</style>